import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { ifProp } from 'styled-tools';
import { Gutter } from '~/shared/components';

export const StyledM40Container = styled.div<{ loadOnSidepanel?: boolean }>(
    ifProp('loadOnSidepanel', {
        [`${Gutter}`]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    })
);

export const StyledGrid = styled.div<{ loadOnSidepanel?: boolean }>(
    {
        display: 'grid',
        gap: 30,
        gridAutoFlow: 'row',
        alignItems: 'start',
    },
    ifProp(
        'loadOnSidepanel',
        {
            gridTemplateColumns: '1fr',
        },
        {
            gridTemplateColumns: '1fr',
            [breakpoints.sm]: {
                gridTemplateColumns: 'calc(33.33% - 15px) calc(66.66% - 15px)',
            },
        }
    )
);

export const StyledContent = styled.div({});

export const StyledForm = styled.div({});

export const StyledModalWrapper = styled.div({
    display: 'grid',
    gap: 30,
    width: '100%',
});
